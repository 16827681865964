import React from 'react';
import './Work.css';
import ImageCell from '../misc/ImageCell/IamgeCell';

const Work = (props) => (
	<div className='work-container'>
		<div className='work-header'>My Portfolio</div>
		<p className='work-description'>
			Here are a few recent projects that I accomplished. Among them are  my personal, college and freelance projects. Each this projects was done using different sets of tools; 
		</p>
		<div className='work-grid_container'>
			<ImageCell
				title='progressives'
				name='Progressives For Congress'
				color='#313131'
				description="'Progressives for Congress' is a small web aplication than allows people to search for progressive candidates in the congressional district they that live in. "
				description2='Technologies used: NodeJS, ReactJS, MongoDB, AWS'
				href='https://www.progressivesforcongress.org'
			/>
			<ImageCell
				title='huts'
				name='HUTS'
				color='#313131'
				description='HUTS is one of my college projects. This website that allows to search for rentals in different countries and book them.'
				description2='Back end for this project is done using NodeJS. Front end is written on vanilla JavaScript'
				href='https://huts.herokuapp.com/'
			/>
			<ImageCell title='gtatools' name='GTA Tools' color='#313131' 
			description="GTA Tools is a showroom website created for my friend who  sells used and new construction tools. He is able to update delete and add new tools from his admin account."
			description2="This project was done using MEAN Stack (MongoDB, Express, Angular and NodeJS)"
			href='http://www.gtatools.ca/'/>
			<ImageCell title='sorting' name="Simple Sorting Algorithms" color='#313131'
			description="This small project is a 3d visualization of how selection and buble sort algorithms work."
			description2="Tech used: BabylonJS, JavaScript"
			href="https://babylonjs-serg9891.vercel.app/"/>
			<ImageCell title='game' 
			href="https://game-serg9891.vercel.app"
			name="Shooting game"
			color='#313131'
			description="Simple shooter game. This game was created using Canvas API and JavaScript."/>
			
		</div>
	</div>
);

export default Work;
