import React from 'react';
import { Route } from 'react-router-dom';
import Main from '../Main/Main';
import Skills from '../Skills/Skills';
import Work from '../Work/Work';
import Contact from '../Contact/Contact';
import './Content.css';

const Content = () => {
	return (
		<div className='content-container'>
			<Route path='/' exact component={Main}></Route>
			<Route path='/skills' exact component={Skills}></Route>
			<Route path='/work' exact component={Work}></Route>
			<Route path='/contact' exact component={Contact}></Route>
		</div>
	);
};

export default Content;
