import React from 'react';
import './Scroll.css';

const Scroll = () => {
	return (
		<div className='scroll-container'>
			scroll
			<i className='angle double down icon yellow'></i>
		</div>
	);
};

export default Scroll;
