import React from 'react';
import './Bar.css';
const Bar = (props) => {
	return (
		<div>
			<div className="bar-title">{props.title}</div>
			<div className='bar-container'>
				<div
					className='bar-meter'
					style={{ width: `${props.width}%`, backgroundColor: props.color }}
				></div>
			</div>
		</div>
	);
};

export default Bar;
