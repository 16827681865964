import './Myself.css';

import React from 'react';

const Myself = (props) => {
	return (
		<div className='myself-container'>
			<div className='work-header'>About Myself</div>
			<div className='work-description'>
				My name is Sergey Sheludko. Energetic full-stack developer; Seneca college graduate with
				a passion for web development and design. Over 3 years of experience working on web
				applications, personal and college projects. Interested in IT and everything in its
				orbit. Life-time learner in addition to uncommon ability to grasp new technical concepts
				and utilizing them to achieve project objectives.
			</div>
		</div>
	);
};

export default Myself;
