import './Isometric.css';
import React, { useEffect } from 'react';

const Isometric = (props) => {
	useEffect(() => {
		let text = document.getElementById('text');
		let shadow = '';
		for (let i = 0; i < 10; i++) {
			shadow += (shadow ? ',' : '') + -i * 1 + 'px ' + i * 1 + 'px 0 black';
		}
		text.style.textShadow = shadow;
	}, []);
	return (
		<div id='text-cont'>
			<div
				id='text'
				data-text='NodeJS CSS MongoDB Angular HTML ReactJS ES5/ES6 Bash JavaScript MVC C++ Java Express SASS SQL Git JSON npm REST'
			>
				NodeJS CSS MongoDB Angular HTML ReactJS ES5/ES6 Bash JavaScript MVC C++ Java Express
				SASS SQL Git JSON npm REST
			</div>
		</div>
	);
};

export default Isometric;
