import React, { useEffect } from 'react';
import './Main.css';

import Skills from '../Skills/Skills';
import Work from '../Work/Work';
import Contact from '../Contact/Contact';
import Scroll from '../misc/Scroll/Scroll';
import Myself from '../Myself/Myself';
const Main = (props) => {
	useEffect(() => {
		let vh = window.innerHeight * 0.01;

		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', `${vh}px`);

		window.addEventListener('resize', () => {
			// We execute the same script as before
			let vh = window.innerHeight * 0.01;

			document.documentElement.style.setProperty('--vh', `${vh}px`);
		});
	}, []);

	const onContact = () => {
		props.history.push('/contact');
	};

	return (
		<div className='main-container'>
			<div className='main-greeting'>
				<div></div>
				<div>
					<h1 className='main-greeting-text'>
						<span className='main-hi'>Hi,</span>
						<br /> I am{' '}
						<span className='main-capital' style={{ color: 'rgb(248, 215, 82)' }}>
							S
						</span>
						ergey,
						<br /> <span className='main-header'>Web developer</span>
					</h1>
					<p className='main-description'>Back end / front end programmer</p>

					<button className='main-btn' onClick={onContact}>
						Contact me!
					</button>
				</div>

				<div className='main-scroll'>
					<Scroll></Scroll>
					<Scroll></Scroll>
				</div>
			</div>
			<Skills></Skills>
			<Myself></Myself>
		
			<Work></Work>
				
			<Contact></Contact>
			
			
		</div>
	);
};

export default Main;
