import React from 'react';
import {Link} from 'react-router-dom';

import './Menu.css';
const Menu = () => {
	return (
		<div className='menu-container'>
			<div className='menu-logo-cont'>
				<img
					className='menu-logo'
					src={`${process.env.PUBLIC_URL}/assets/images/android-chrome-512x512.png`}
					alt='logo'
				></img>

				<p className='menu-name'><span style={{color:'rgb(248, 215, 82)'}}>S</span>ergey <span style={{color:'rgb(248, 215, 82)'}}>Sh</span>eludko</p>
				<p className='menu-name-pos'>A full stack developer</p>
			</div>
			<div className='menu-menu-cont'>
				<ul>
					<Link to="/"><li>About</li></Link>
					<Link to="/skills"><li>My Skills</li></Link>
					<Link to="/work"><li>Work</li></Link>
					<Link to="/contact"><li>Contact</li></Link>
				</ul>
			</div>
			<div className='menu-icons'>
				<a href='https://www.facebook.com/sergey.sheludko' rel='noreferrer' target='_blank'>
					<i className='facebook icon big yellow'></i>
				</a>
				<a
					href='https://www.linkedin.com/in/serhiy-sheludko-9a04121ab/'
					rel='noreferrer'
					target='_blank'
				>
					<i className='linkedin icon big yellow'></i>
				</a>
			</div>
		</div>
	);
};

export default Menu;
