import React, { useState } from 'react';
import './Contact.css';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import mapStyles from './mapStyles';
import axios from 'axios';
import validator from 'email-validator';

const mapContainerStyle = {
	width: '100%',
	height: '100vh'
};

const center = {
	lat: 43.67024,
	lng: -79.386727
};
const options = {
	styles: mapStyles,
	disableDefaultUI: true,
	zoomControl: true
};

const Contact = (props) => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: 'AIzaSyBQprvU6xGsUHgcbUIMBBhyareRSDTMjmk'
	});

	const clearInputs = () => {
		setName('');
		setEmail('');
		setSubject('');
		setMessage('');
	};

	const onSendClicked = () => {
		if (!name || !email || !subject || !message) {
			alert('Please fill up all the required fields!');
			return;
		}
		if (!validator.validate(email)) {
			alert('Please enter valid email!');
			return;
		}
		axios
			.post('http://petro-store-server.herokuapp.com/api/ssh', {
				params: {
					name,
					message,
					email,
					subject
				}
			})
			.then(function (response) {
				console.log(response);

				clearInputs();
			})
			.catch(function (error) {
				console.log(error);
			})
			.then(function () {});
	};

	if (loadError) {
		return 'Error loading maps';
	}
	if (!isLoaded) return 'LoadingMaps';
	return (
		<div className='contact-container'>
			<div className='contact-cell1'>
				<h1>Contact me</h1>
				<p>
					If you have any questions about myself, I will be happy to answer them. Email me!{' '}
				</p>
				<div className='contact-form-container'>
					<div className='contact-name'>
						<input
							type='text'
							onChange={(e) => setName(e.target.value)}
							value={name}
							placeholder='Name'
						></input>
					</div>
					<div className='contact-email'>
						<input
							type='text'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder='Email'
						></input>
					</div>
					<div className='contact-subject'>
						<input
							type='text'
							value={subject}
							onChange={(e) => setSubject(e.target.value)}
							placeholder='Subject'
						></input>
					</div>
					<div className='contact-message'>
						<textarea
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							placeholder='Message'
						></textarea>
					</div>
				</div>
				<div className='contact-send-cont'>
					<button onClick={onSendClicked}>Send message</button>
				</div>
				
			</div>
			<div>
				<div className='contact-map_label'>
					Sergey Sheludko <br></br> Toronto, ON, Canada <br></br>
					<br></br> <span style={{ color: 'yellow' }}>@</span>: ssheludko.dev@gmail.com
				</div>
				<GoogleMap
					mapContainerStyle={mapContainerStyle}
					zoom={13}
					center={center}
					options={options}
				>
					<Marker
						position={center}
						icon={{
							url: `assets/images/loc.png`,
							scaledSize: new window.google.maps.Size(50, 50),
							origin: new window.google.maps.Point(0, 0),
							anchor: new window.google.maps.Point(25, 25)
						}}
					/>
				</GoogleMap>
			</div>
		</div>
	);
};

export default Contact;
