import './Burger.css';
import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';

const Burger = (props) => {
	const [open, setOpen] = useState(false);

	const onMenuToggle = () => {
		if (!open) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'visible';
		}
		setOpen(!open);
	};

	const onNavTo = () => {
		onMenuToggle();
	};

	return (
		<Fragment>
			<div className='container' onClick={onMenuToggle}>
				<div style={{ display: `${open ? 'none' : 'block'}` }}>
					<div className='bar1'></div>
					<div className='bar2'></div>
					<div className='bar3'></div>
				</div>
				<div style={{ display: `${open ? 'block' : 'none'}` }}>
					<div className='bar4'></div>
					<div className='bar5'></div>
					<div className='bar6'></div>
				</div>
			</div>
			<div className='burger-menu-container' style={{ display: `${open ? 'block' : 'none'}` }}>
				<div className='burger-logo-cont'>
					<img
						className='burger-menu-logo'
						src={`${process.env.PUBLIC_URL}/assets/images/android-chrome-512x512.png`}
						alt='logo'
					></img>
					<h3>Sergey Sheludko</h3>
					<p>Full Stack developer</p>
				</div>
				<div className='burger-menu'>
					<ul>
						<Link to="/"><li onClick={onNavTo}>About</li></Link>
						<Link to="/skills"><li onClick={onNavTo}>My Skills</li></Link>
						<Link to="/work"><li onClick={onNavTo}>Work</li></Link>
						<Link to="/contact"><li onClick={onNavTo}>Contact</li></Link>
					</ul>
					<div className='menu-icons'>
						<a
							href='https://www.facebook.com/sergey.sheludko'
							rel='noreferrer'
							target='_blank'
						>
							<i className='facebook icon big yellow'></i>
						</a>
						<a
							href='https://www.linkedin.com/in/serhiy-sheludko-9a04121ab/'
							rel='noreferrer'
							target='_blank'
						>
							<i className='linkedin icon big yellow'></i>
						</a>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Burger;
