import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Menu from '../Menu/Menu';
import Content from '../Content/Content';
import Burger from '../Burger/Burger'
const App = () => {
	// const arr = ['HTML5', 'CSS', 'JS', 'Node JS'];
	// useEffect(() => {
	// }, []);


	return (
		<div className='app-container'>
			<div className='app-menu'>
				<BrowserRouter>
					<Burger></Burger>
					<Menu></Menu>
					<Content />
				</BrowserRouter>
			</div>
			
		</div>
	);
};

export default App;
