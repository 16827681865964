import React from 'react';
import './ImageCell.css';

const ImageCell = (props) => {
	return (
		<div className='flip-card'>
			<div className='flip-card-inner'>
				<div className='flip-card-front'  style={{backgroundColor:props.color}}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/portfolio/${props.title}.png`} alt='Avatar' style={{height:'100%', overflow: 'hidden'}}></img>
             
				</div>
				<div className='flip-card-back'>
					<h1>{props.name}</h1>
					<p>{props.description}</p>
					<p>{props.description2}</p>
               <a href={props.href} target="_blank" rel="noreferrer"><button className="imagecell-button">VIEW PROJECT</button></a>
				</div>
			</div>
		</div>
	);
};

export default ImageCell;

//<img src={`${process.env.PUBLIC_URL}/assets/images/portfolio/${props.title}.png`} alt='Avatar' style={{height:'100%', overflow: 'hidden'}}></img>