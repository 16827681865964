import React,{Fragment} from 'react';
import './Skills.css';
import Bar from '../misc/Bar/Bar';
import Isometric from '../Isometric/Isometric';

const Skills = () => {
	return (
		<Fragment>
			<div className='skills-container'>
				<div className='skills-cell_one'>
					<h1 className='skills-header'>
						Skills &<br></br> Expirience
					</h1>
					<p className='skills-description'>
						I am a web developer with more than 3 years of experience in the field and
						computer porgrammer diploma. My focus is both front and back end. I utilize such tools as ReactJS and Angular when working with front end.
						For server side my tool of choice is NodeJS. Depending on the project I can work with SQL or NoSQL databases such as Oracle and MongoDB. I am also familiar with Java and C++ if required by any project.
					</p>
				</div>
				<div className='skills-cell_two'>
					<Bar
						width={90}
						color='rgb(248, 215, 82)'
						title='MERN Stack (MongoDB, Express, ReactJS, NodeJS)'
					></Bar>
					<Bar
						width={78}
						color='red'
						title='ReactJS, Angular, JavaScript, ES5/ES6, HTML5'
					></Bar>
					<Bar width={88} color='#9373fc' title='NodeJS, C++, Java'></Bar>
					<Bar width={75} color='rgb(248, 215, 82)' title='MongoDB, Oracle, MySQL'></Bar>
				</div>
			</div>
			<Isometric></Isometric>
		</Fragment>
	);
};

export default Skills;
